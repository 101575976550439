.cs-dashboard-header-logo {
  width: 3em;
  height: 2.5em;
  margin-top: .3em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-dashboard-header-logo-xs {
  width: 3em;
  height: 2.5em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-dashboard-header-col-start {
  padding-left: 2em!important;
}

.cs-dashboard-header-col-end {
  padding-right: 1.5em!important;
}

.cs-dashboard-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  padding: 0!important;
}

.cs-dashboard-header-cursor {
  cursor: default!important;
}

.cs-dashboard-header-color {
  color: #0B7DBB!important;
}

.cs-dashboard-header-color-skill {
  color: #12B1B9!important;
}

.cs-dashboard-header-button-icon {
  height: 1.5em!important;
}

.cs-dashboard-header-button-nav {
  color: #0B7DBB!important;
}

.cs-dashboard-header-button-nav-left {
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-top-color: #ccc;
  border-top-width: thin;
  border-top-style: solid;
  padding-right: 0!important;
}

.cs-dashboard-header-button-nav-right {
  padding-left: 0!important;
}

.cs-dashboard-header-button-nav-right-small {
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-top-color: #ccc;
  border-top-width: thin;
  border-top-style: solid;
  padding-left: 0!important;
}

.cs-dashboard-header-menu-text {
  padding-left: .75em!important;
}
